
import {
    Modal,
    ModalBody,
    Table
} from "reactstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Box, TextField, IconButton, Typography, Button, MenuItem,CircularProgress, Paper, TableContainer, TableHead, TableRow, TableCell, TableBody  } from '@mui/material';
import React, { useState, useEffect } from "react";
import { analyzeResponse } from "apis/prompt.api";
import { NotificationBadge } from "components/NotificationBadge/NotificationBadge";
import globalSearchStore from "stores/globalSearchStore";
import './index.css'
import { marked } from 'marked';
import Feedback from "views/Feedback/Feedback";


export const AnalyzeModal = ({ setSelectedReport, selectedReport, fileName, openModal, setOpenModal, showAIReports, userDetails, setUserDetails,responseId,abnormalBiomarkers,aiResponse,serialId,summaryContent,isSpinner,setIsSpinner,analyzeStart,setAnalyzeStart,feedbackDetails,setSummaryContent,setAiResponse,retrieveExcelData }) => {
    const [notificationBadge, setNotificationBadge] = useState({
        showNotification: false,
        isSuccess: null,
        message: ""
    })
    useEffect(()=>{
       console.log("aiResponse>>>>>>>>>>>>>>>>>",aiResponse?.biomarkers) 
    },[aiResponse])

    const colors = {
        header: "#8C8CFF",
        background: "#F9F9F9",
        iconColor: "#8C8CFF",
    };
    const { tableData, setTableData } = globalSearchStore();
    const [isSameSample, setIsSameSample] = useState(true)

    const handleClose = () => {
        setOpenModal(false);
        setSelectedReport(null);
        setAnalyzeStart(false);
        setIsSpinner(false)
        setUserDetails({});
        setSummaryContent('');
        setAiResponse([]);
    };

    const handleBiomarkerChange = (index, field, value) => {
        const updatedReport = { ...selectedReport };
        updatedReport.json[index][field] = value;
        setSelectedReport(updatedReport);
        setIsSameSample(false)
    };

    const [messageIndex, setMessageIndex] = useState(0);
    const messages = [
        "Analyzing your report...",
        "Generating summary...",
        "Finalizing suggestions...",
        "This may take a moment.",
    ];
  
    useEffect(() => {
        if (messageIndex < messages.length - 1) {
            const interval = setInterval(() => {
                setMessageIndex((prevIndex) => prevIndex + 1);
            }, 5000);
  
            return () => clearInterval(interval); 
        }
    }, [messageIndex]);

    // const handleBiomarkerReferenceRangeChange = (index, field, value) => {
    //     const updatedReport = { ...selectedReport };
    //     const updatedJson = [...updatedReport.json];
    //     updatedJson[index] = { ...updatedJson[index], [field]: value };
    //     updatedReport.json = updatedJson;
    //     setSelectedReport(updatedReport);
    // };


    const handleDeleteRow = (index) => {
        const updatedReport = { ...selectedReport };
        updatedReport.json.splice(index, 1);
        setSelectedReport(updatedReport);
    };
    // const handleAddRow = () => {
    //     const updatedReport = { ...selectedReport };
    //     updatedReport?.json.push({ Biomarker: "", Value: "" });
    //     setSelectedReport(updatedReport);
    // };

    const handleAnalyzeReport = async (report, fileNameDetails) => {
        setIsSpinner(true)
        setAnalyzeStart(true)
        const response = await analyzeResponse({ excelJsonData: report?.json, fileName: fileNameDetails, userDetails: userDetails, serialId:serialId, isSameSample: isSameSample })
        if (response?.status) {
            const { aiResponse } = response?.updateFile
            showAIReports(aiResponse, response?.updateFile?.summary, response?.updateFile?.userDetails, response?.updateFile)
            setTableData([...tableData, response?.updateFile])
            retrieveExcelData()
        } else {
            setNotificationBadge({ showNotification: true, isSuccess: false, message: response?.message });
        }

        setIsSpinner(false)
    };

    const renderKeyValuePairs = (data) => {
        return Object.entries(data)
          .filter(([key, value]) => value !== '' && (Array.isArray(value) ? value.length > 0 : true))
          .map(([key, value]) => {
            const formattedKey = key
              .replace(/_/g, ' ')
              .replace(/^\w/, c => c.toUpperCase());
    
            return (
              <Box
                key={key}
                p={1}
                mb={1}
                bgcolor="#f7f7f7"
                borderRadius={1}
              >
               <Typography variant="body1" sx={{ fontWeight: "bold", display: "inline" }}>
                  {formattedKey}:
                </Typography>
                <Typography variant="body2" sx={{ display: "inline", ml: 1 }}>
                  {Array.isArray(value) ? value.join(", ") : value}
                </Typography>
              </Box>
            );
          });
      };


    const handleInputChange = (field, value) => {
        setUserDetails((prevReport) => ({
            ...prevReport,
            [field]: value,
        }));
    };
    return (
        <>
            {notificationBadge?.showNotification && <NotificationBadge notificationBadge={notificationBadge} setNotificationBadge={setNotificationBadge} />}
            <Modal isOpen={openModal} style={{ maxWidth: analyzeStart ? '100rem' : '60rem', }}>
                {/* Close Icon */}
                <IconButton
                    style={{ position: 'absolute', top: '10px', right: '10px', zIndex: '1001' }}
                    onClick={handleClose}
                >
                    <CloseIcon style={{ color: "#000051" }} />
                </IconButton>
                <ModalBody style={{ display: "flex", backgroundColor: colors.background, }}>
                    <Box
                        style={{
                            position: 'relative',
                            width: analyzeStart ? '40%' : '100%',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: 24,
                            maxHeight: '46rem',
                            overflowY: 'auto',
                        }}
                        sx={{
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >

                        <Typography variant="h6" align="center" gutterBottom color="#000051">
                            Edit Report
                        </Typography>
                        <Box
                            sx={{
                                marginTop: 2,
                                maxHeight: "40rem",
                                overflowY: "auto",
                            }}
                        >
                            {userDetails?.Name !== undefined && Object.keys(userDetails)?.map((key, index) => {
                                const isDropdown = key === 'Gender' || key === 'Race';
                                const dropdownOptions = {
                                    Gender: ['Male', 'Female', 'Other'],
                                    Race: ['Asian', 'African American', 'Hispanic or Latino', 'White']
                                };

                                // Helper to remove units from Height and Weight
                                const removeUnit = (value, unit) => value.replace(` ${unit}`, '');

                                return isDropdown ? (
                                    <TextField
                                        key={index}
                                        select
                                        label={key}
                                        variant="outlined"
                                        size="small"
                                        value={userDetails[key]}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        fullWidth
                                        disabled={isSpinner}
                                        style={{cursor: isSpinner ? "not-allowed" : "pointer", marginBottom: '10px', marginTop: '10px' }}
                                    >
                                        {dropdownOptions[key].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                ) : key === 'Height' || key === 'Weight' ? (
                                    <TextField
                                        key={index}
                                        label={key === 'Height' ? 'Height (Inches)' : 'Weight (Pounds)'}
                                        variant="outlined"
                                        size="small"
                                        value={removeUnit(userDetails[key], key === 'Height' ? 'Inches' : 'Pounds')}
                                        onChange={(e) => handleInputChange(key, e.target.value + (key === 'Height' ? ' Inches' : ' Pounds'))}
                                        fullWidth
                                        disabled={isSpinner}
                                        style={{cursor: isSpinner ? "not-allowed" : "pointer", marginBottom: '10px', marginTop: '10px' }}
                                    />
                                ) : (
                                    <TextField
                                        key={index}
                                        label={key}
                                        variant="outlined"
                                        size="small"
                                        value={userDetails[key]}
                                        onChange={(e) => handleInputChange(key, e.target.value)}
                                        fullWidth
                                        disabled={isSpinner}
                                        style={{cursor: isSpinner ? "not-allowed" : "pointer",  marginBottom: '10px', marginTop: '10px' }}
                                    />
                                );
                            })}
                            {selectedReport?.json
                                .map((item, index) => {
                                    if ((item.name === "Name" && item.value === "Value" && item.referenceRange === "Reference Range")) {
                                        return
                                    }
                                    return (
                                        <Box key={index} display="flex" alignItems="center" mb={2} mt={2} zIndex="1">
                                            <TextField
                                                label="Biomarker"
                                                variant="outlined"
                                                size="small"
                                                value={item.name}
                                                fullWidth
                                                disabled
                                                style={{
                                                    marginRight: '10px',
                                                    cursor: 'not-allowed'
                                                }}
                                            />

                                            <TextField
                                                label="Value"
                                                variant="outlined"
                                                size="small"
                                                value={item.value}
                                                onChange={(e) => handleBiomarkerChange(index, 'value', e.target.value)}
                                                fullWidth
                                                disabled={isSpinner}
                                                style={{cursor: isSpinner ? "not-allowed" : "pointer", marginRight: '10px' }}
                                            />
                                            <TextField
                                                label="Reference Range"
                                                variant="outlined"
                                                size="small"
                                                value={item?.referenceRange ?? ""}
                                                style={{
                                                    marginRight: '10px',
                                                    cursor: 'not-allowed'
                                                }}
                                                disabled
                                                // onChange={(e) => handleBiomarkerReferenceRangeChange(index, 'referenceRange', e.target.value)}
                                                fullWidth
                                            />
                                            <IconButton onClick={() => !isSpinner && handleDeleteRow(index)}>
                                                <DeleteIcon style={{cursor: isSpinner ? "not-allowed" : "pointer",color: colors.iconColor }} />
                                            </IconButton>
                                        </Box>
                                    );
                                })}
                            {/* <Box display="flex" justifyContent="center" zIndex="1">
                                <IconButton onClick={handleAddRow}>
                                    <AddCircleIcon style={{ color: colors.iconColor, fontSize: 40 }} />
                                </IconButton>
                            </Box> */}
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="center"
                            mt={3}
                            position="sticky"
                            bottom="0"
                            width="100%"
                            backgroundColor={colors.background}
                            padding="10px 0"
                            zIndex="1000"
                        >
                            <Button
                               disabled={isSpinner}
                                sx={{
                                    background: isSpinner ? "#d6d1d1" : "#1189EF",
                                    color: "white",
                                    textTransform:"capitalize"
                                }}
                                onClick={() => handleAnalyzeReport(selectedReport, fileName)}
                            >
                                Analyze Report
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        style={{
                            position: 'relative',
                            width: analyzeStart ? '60%' : '0%',
                            padding: '20px',
                            borderRadius: '10px',
                            boxShadow: 24,
                            maxHeight: '46rem',
                            overflowY: 'auto',
                        }}
                        sx={{
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                        }}
                    >
                        {isSpinner &&
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    bgcolor: '#00c0ed',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 9999,
                                }}
                            >
                                <Box sx={{ textAlign: 'center' }}>
                                    <CircularProgress size={60} sx={{ color: 'white', mb: 2 }} />
                                    <Typography variant="h6" color="white">
                                        {messages[messageIndex]}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        {!isSpinner && (aiResponse?.length > 0 || summaryContent) &&
                            <>
                                <Typography variant="h6" align="center" gutterBottom color="#000051">
                                    Biomarker Details
                                </Typography>
                                <Box mb={2} display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                                    <Box>
                                        <Typography variant="h5" style={{ marginBottom: '10px' }}>
                                            User Info
                                        </Typography>
                                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                            <tbody>
                                                {userDetails?.Name && Object.entries(userDetails)?.map(([key, value]) => (
                                                    <tr key={key}>
                                                        <td style={{ padding: '16px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                                                            {key}
                                                        </td>
                                                        <td style={{ padding: '16px', border: '1px solid #ddd' }}>
                                                            {value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Box>

                                    <Box>
                                        <Feedback responseId={responseId} feedbackDetails={feedbackDetails} />
                                    </Box>
                                </Box>

                                {abnormalBiomarkers && abnormalBiomarkers?.length > 0 &&
                                    <Paper elevation={3} sx={{ p: 2, mb: 2, width: '100%', }}>
                                        <Typography variant="h6" sx={{ color: colors.header, fontWeight: 'bold', mb: 1 }}>
                                            Abnormal Biomarkers List:
                                        </Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell><strong>Name</strong></TableCell>
                                                        <TableCell><strong>Value</strong></TableCell>
                                                        <TableCell><strong>Reference Range</strong></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {abnormalBiomarkers.map((biomarker, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{biomarker.name}</TableCell>
                                                            <TableCell style={{ color: "red" }}>{biomarker.value}</TableCell>
                                                            <TableCell>{biomarker.referenceRange}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                }
                                <Typography variant="h5" style={{ marginBottom: '10px' }}>
                                    Summary
                                </Typography>
                                <div dangerouslySetInnerHTML={{ __html: marked(summaryContent) }} />

                                {/* Summary Details */}
                                <Typography variant="h5" style={{ marginBottom: '10px' }}>
                                    Report Details
                                </Typography>
                                {/* {aiResponse?.biomarkers?.length > 0 && aiResponse?.biomarkers
                                    .filter(biomarker => biomarker?.["biomarkerType"] !== 'normal')
                                    .map((biomarker, index) => (
                                        <Paper key={index} elevation={3} style={{ padding: '20px', marginBottom: '20px', width: '100%' }}>
                                            <Typography variant="h6" style={{ marginBottom: '10px' }}>
                                                {biomarker.name}
                                            </Typography>
                                            {renderKeyValuePairs(biomarker)}
                                        </Paper>
                                    ))
                                } */}
                                {aiResponse?.biomarkers?.length > 0 && aiResponse?.biomarkers
                                .filter(biomarker => 
                                    biomarker?.["BiomarkerType"] !== 'normal' && 
                                    biomarker?.["BiomarkerType"] !== 'Normal' && 
                                    biomarker?.["Biomarker Type"] !== 'normal' && 
                                    biomarker?.["Biomarker Type"] !== 'Normal' && 
                                    biomarker?.["biomarkerType"] !== 'normal' && 
                                    biomarker?.["biomarkerType"] !== 'Normal' && 
                                    biomarker?.["type"] !== 'normal' && 
                                    biomarker?.["type"] !== 'Normal'
                                )
                                .map((biomarker, index) => (
                                    <Paper key={index} elevation={3} style={{ padding: '20px', marginBottom: '20px', width: '100%' }}>
                                        <Typography variant="h6" style={{ marginBottom: '10px' }}>
                                            {biomarker['Name']}
                                        </Typography>
                                        {renderKeyValuePairs(biomarker)}
                                    </Paper>
                                ))
                            }

                            </>
                        }
                    </Box>
                </ModalBody>
            </Modal>
        </>
    )
}