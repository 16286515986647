import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Card,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { Button, IconButton, CircularProgress} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { analyzeResponse } from '../../apis/prompt.api';
import { fetchExcelData, fetchAllSampleReports, deleteSampleReportFile } from '../../apis/upload.api';
import { NotificationBadge } from 'components/NotificationBadge/NotificationBadge';
import DownloadIcon from '@mui/icons-material/Download';
import { SAMPLE_REPORTS } from "constants";
import globalSearchStore from "stores/globalSearchStore";
import { styled } from '@mui/material/styles'
import './index.css'
import { AnalyzeModal } from "components/AnalyzeModal/AnalyzeModal";

const colors = {
  header: "#8C8CFF",
  background: "#F9F9F9",
  iconColor: "#8C8CFF",
};

const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: colors.iconColor,
  transition: 'color 0.3s ease',
  '&:hover': {
    color: 'red',
  },
}));

const SampleReportsTables = () => {
  const { tableData, tableLoader, tablePage, setTablePage, setTableLoader, setTableData, tableTotalPages, setTableTotalPages } = globalSearchStore();
  const LIMIT = 5;
  const navigate = useNavigate();
  const [aiResponse, setAiResponse] = useState([])
  const [loader, setLoader] = useState(false)
  const [notificationBadge, setNotificationBadge] = useState({
    showNotification: false,
    isSuccess: null,
    message: ""
  })
  const [summaryContent, setSummaryContent] = useState('');
  const [userDetails, setUserDetails] = useState({})
  const [responseId, setResponseId] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [serialId,setSerialId] = useState("");
  const [fileName, setFileName] = useState("");
  const [abnormalBiomarkers, setAbnormalBiomarkers] = useState('');
  const [isSpinner, setIsSpinner] = useState(false);
  const [analyzeStart, setAnalyzeStart] = useState(false);
  const [isSameSample, setIsSameSample] = useState(true);
  const [editLoader, setEditLoader] = useState("");
  const [feedbackDetails, setFeedbackDetails] = useState({
    feedback: "",
    rating: 0
  });
  useEffect(() => {
    retrieveExcelData();
  }, [tablePage]);


  const retrieveExcelData = async () => {
    try {
      setTableLoader(true);
      const requestData = { page: tablePage, limit: LIMIT, searchText: "" };
      const result = await fetchAllSampleReports(requestData);
      if (result && result?.status) {
        setTableData(result.data);
        setTableTotalPages(result?.totalPage || 0);
        if (result?.totalPage < tablePage) setTablePage(1);
      } else {
        setNotificationBadge({ showNotification: true, isSuccess: result?.status, message: result?.message });
      }
    } catch (error) {
      console.error("Failed to fetch sample reports:", error);
      setNotificationBadge({ showNotification: true, isSuccess: false, message: "Failed to retrieve data." });
    } finally {
      setTableLoader(false);
    }
  };

  

  const handleEditClick = async (report) => {
    setEditLoader(report?.id)
    setSerialId(report?.serialId)
    const response = await fetchExcelData({ filename: report?.filename })
    if (response?.status) {
      setSelectedReport({ ...report, json: response?.excelJsonData });
      setUserDetails(response?.userDetails)
      setFileName(report?.filename)
      setOpenModal(true);
    } else {
      setNotificationBadge({ showNotification: true, isSuccess: false, message: response?.message });
    }
    setEditLoader("")
  };

  const handleAnalysis = async (report, fileNameDetails) => {
    setSerialId(report?.serialId)
    setLoader(report?.id)
    const responseFile = await fetchExcelData({ filename: fileNameDetails })
    if (responseFile?.status) {
      setSelectedReport({ ...report, json: responseFile?.excelJsonData });
      setUserDetails(responseFile?.userDetails)
      setFileName(fileNameDetails)
      setOpenModal(true);
      setIsSpinner(true)
      setAnalyzeStart(true)
      const result = responseFile.excelJsonData.map(item => ({
        name: item.name,
        value: item.value,
        referenceRange: item?.referenceRange
      }));
      console.log("Analyzed Report:", { excelJsonData: result, fileName: fileNameDetails, userDetails: responseFile?.userDetails });
      if (result) {
        const response = await analyzeResponse({ excelJsonData: result, fileName: fileNameDetails, userDetails: responseFile?.userDetails,serialId:report?.serialId, isSameSample:true,isSameSample:isSameSample })
        console.log("Analyzed Report: response", response);
        if (response?.status) {
          const { aiResponse } = response?.updateFile
          setAiResponse(aiResponse)
          setSummaryContent(response?.updateFile?.summary)
          setAbnormalBiomarkers(response?.filteredAbnormal)
          setUserDetails(response?.updateFile?.userDetails)
          setResponseId(response?.id)
        } else {
          setNotificationBadge({ showNotification: true, isSuccess: false, message: response?.message });
        }
      }
      setLoader('');
      setIsSpinner(false)
    } else {
      setNotificationBadge({ showNotification: true, isSuccess: false, message: responseFile?.message });
    }
  };

  const handleDeleteFiles = async (fileName) => {
    try {
      const remainingData = await deleteSampleReportFile(fileName);
      if (remainingData?.status) {
        setNotificationBadge({ showNotification: true, isSuccess: remainingData?.status, message: remainingData?.message });
        retrieveExcelData();
      }
    }
    catch (error) {
      console.error("Failed to delete file:", error.message || error)
      setNotificationBadge({ showNotification: true, isSuccess: false, message: "Failed to delete file" });
    }
  }


  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > tableTotalPages) return;
    setTablePage(newPage);
  };

  const handleDownload = (fileName) => {
    const fileUrl = `${process.env.REACT_APP_BACKEND_URL}/${fileName}`
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }

  const showAIReports = async(data, summary, userdetails, report) => {
    console.log("report>>>>>>>>>>>>", report)
    setIsSpinner(false)
    setAnalyzeStart(true)
    setSerialId(report?.serialId)
    const response = await fetchExcelData({ filename: report?.fileName })
    if (response?.status) {
      setSelectedReport({ json: response?.excelJsonData });
      setFileName(report?.fileName)
    }
    if (report?.filteredAbnormal?.length > 0) {
      setAbnormalBiomarkers(report?.filteredAbnormal)
    }
    setResponseId(report?.id)
    setFeedbackDetails({ feedback: report?.feedback, rating: report?.rating })
    setAiResponse(data)
    setSummaryContent(summary);
    setUserDetails(userdetails)
    setOpenModal(true);
    retrieveExcelData();
  }

  return (
    <>
      {openModal && <AnalyzeModal openModal={openModal} selectedReport={selectedReport} fileName={fileName} setSelectedReport={setSelectedReport} setOpenModal={setOpenModal} showAIReports={showAIReports} userDetails={userDetails} setUserDetails={setUserDetails} responseId={responseId} abnormalBiomarkers={abnormalBiomarkers} aiResponse={aiResponse} serialId={serialId} summaryContent={summaryContent} isSpinner={isSpinner} setIsSpinner={setIsSpinner} analyzeStart={analyzeStart} setAnalyzeStart={setAnalyzeStart} setFeedbackDetails={setFeedbackDetails}  setSummaryContent={setSummaryContent} setAiResponse={setAiResponse} retrieveExcelData={retrieveExcelData} />}
      <Header />
      <Container className="mt--7" fluid>
        {notificationBadge?.showNotification && <NotificationBadge notificationBadge={notificationBadge} setNotificationBadge={setNotificationBadge} />}
        <Row>
          <div className="col">
            <div className="upload-report" style={{ textAlign: "right", }}>
              <Button
                onClick={() => navigate('/admin/report-analyzer')}
                sx={{
                  margin: 1,
                  color: "#fff",
                  textTransform: "capitalize",
                  backgroundColor: "#00c0ed",
                  "&:hover": { backgroundColor: "#00c0ed" },
                }}
              >
                Upload Report
              </Button>
            </div>
              <Card className="shadow">
                {tableLoader ? (
                  <div className="text-center my-4">
                    <Spinner style={{ width: "3rem", height: "3rem" }} />
                  </div>
                ) : tableData.length === 0 ? (
                  <div className="text-center my-4">
                    <h4>No data available</h4>
                  </div>
                ) : (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Sample Id</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Gender</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Age</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Race</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Height</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Weight</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Sample Reports Name</th>
                        <th scope="col" style={{ fontSize: "0.8rem", textAlign: "center" }}>Actions</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {tableData && tableData.map((report, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.serialId ?? ""}</td>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.gender}</td>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.age}</td>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.race}</td>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.height}</td>
                          <td style={{ textAlign: "center", verticalAlign: "middle" }} >{report?.weight}</td>
                          <td onClick={() => handleDownload(report?.filename)} style={{ textAlign: "center", verticalAlign: "middle", cursor: "pointer" }}>
                            {report?.filename}
                            <IconButton onClick={() => handleDownload(report?.filename)} aria-label="download" size="small">
                              <DownloadIcon />
                            </IconButton>
                          </td>
                          <td style={{ textAlign: "center", verticalAlign: "middle", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>

                            <Badge color="primary" style={{ fontSize: '0.8rem', padding: '0.4rem 1rem', cursor: "pointer", textTransform: "capitalize" }}
                              onClick={() => handleEditClick(report)}>
                              {editLoader === report.id ? (
                                <CircularProgress size={14} style={{ color: '#00c0ed', marginRight: '0.5rem' }} />
                              ) : "Edit"}
                            </Badge>
                            <Badge
                              style={{ fontSize: '0.8rem', padding: '0.4rem 1rem', background: "rgb(213 227 237 / 50%)", color: "#1189ef", cursor: 'pointer', textTransform: "capitalize" }}
                              onClick={() => handleAnalysis(report, report.filename)}
                            >
                              {loader === report.id ? (
                                <CircularProgress size={14}  style={{ color: '#00c0ed', marginRight: '0 0.5rem' }} />
                              ) : "Analyze"}
                            </Badge>

                            {!SAMPLE_REPORTS.includes(report.filename) && <Badge
                              style={{padding: '0.3rem 1rem', background: "rgb(213 227 237 / 50%)", color: "#1189ef", cursor: 'pointer', textTransform: "capitalize" }}
                              onClick={() => handleDeleteFiles(report.filename)}
                            >
                              <StyledDeleteIcon />
                            </Badge>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
                {!tableLoader && tableData?.length > 0 && (
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                        <PaginationItem disabled={tablePage <= 1}>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              handlePageChange(tablePage - 1);
                            }}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>
                        {Array.from({ length: tableTotalPages }, (_, i) => (
                          <PaginationItem key={i} active={tablePage === i + 1}>
                            <PaginationLink
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                handlePageChange(i + 1);
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={tablePage >= tableTotalPages}>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              handlePageChange(tablePage + 1);
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                )}
              </Card>
            
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SampleReportsTables;
