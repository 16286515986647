import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Spinner,
  Tooltip
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { fetchExcelData, retrieveAllFile,deleteReportFile } from "apis/upload.api";
import { IconButton} from "@mui/material";
import globalSearchStore from "stores/globalSearchStore";
import useUserStore from '../../stores/userStore';
import { AnalyzeModal } from "components/AnalyzeModal/AnalyzeModal";
import './style.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles'
import { NotificationBadge } from "components/NotificationBadge/NotificationBadge";

const ReadMore = ({ text, maxLength = 30 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
 
  const isTextLong = text?.length > maxLength;
  const displayedText = isExpanded || !isTextLong ? text : `${text.slice(0, maxLength)}...`;

  return (
    <div>
      <span>{displayedText}</span>
      {isTextLong && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
          <span onClick={toggleReadMore} style={{ cursor: 'pointer', marginLeft: '5px' }}>
            {isExpanded ? 'Show less' : 'Read more'}
          </span>
          <IconButton onClick={toggleReadMore} size="small">
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      )}
    </div>
  );
};

const colors = {
  header: "#8C8CFF",
  background: "#F9F9F9",
  iconColor: "#8C8CFF",
};

const StyledDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  color: colors.iconColor,
  transition: 'color 0.3s ease',
  '&:hover': {
    color: 'red',
  },
}));

const Tables = () => {
  const { fetchUserData, user } = useUserStore();
  const [summaryContent, setSummaryContent] = useState('');
  const [aiResponse, setAiResponse] = useState([])
  const [userDetails, setUserDetails] = useState({})
  const { tableData, tableLoader, tablePage, setTablePage, setTableLoader, setTableData, tableTotalPages, setTableTotalPages } = globalSearchStore();
  const LIMIT = 10;
  const [abnormalBiomarkers, setAbnormalBiomarkers] = useState('');
  const [serialId, setSerialId] = useState("");
  const [responseId, setResponseId] = useState();
  const [feedbackDetails, setFeedbackDetails] = useState({
    feedback: "",
    rating: 0
  })
  const [selectedReport, setSelectedReport] = useState(null);
  const [fileName, setFileName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [btnLoader, setBtnLoader] = useState("");
  const [isSpinner, setIsSpinner] = useState(false)
  const [analyzeStart, setAnalyzeStart] = useState(false)
  const [loader, setLoader] = useState('');
  const [notificationBadge, setNotificationBadge] = useState({
    showNotification: false,
    isSuccess: null,
    message: ""
  })

  const showAIReports = async(data, summary, userdetails, report) => {
    console.log("report>>>>>>>>>>>>", report)
    setIsSpinner(false)
    setAnalyzeStart(true)
    setSerialId(report?.serialId)
    setLoader(report?.id)
    const response = await fetchExcelData({ filename: report?.fileName })
    if (response?.status) {
      setSelectedReport({ json: response?.excelJsonData });
      setFileName(report?.fileName)
    }
    if (report?.filteredAbnormal?.length > 0) {
      setAbnormalBiomarkers(report?.filteredAbnormal)
    }
    setResponseId(report?.id)
    setFeedbackDetails({ feedback: report?.feedback, rating: report?.rating })
    setAiResponse(data)
    setSummaryContent(summary);
    setUserDetails(userdetails)
    setOpenModal(true);
    setLoader('')
  }

  useEffect(() => {
    retrieveExcelData()
  }, [tablePage]);

  const retrieveExcelData = async() => {
    try {
      setTableLoader(true);
      const requestData = { page: tablePage, limit: LIMIT, searchText: "" };
      const result = await retrieveAllFile(requestData);
      if (result && result?.status) {
        setTableData(result.files);
        setTableTotalPages(result?.totalPage || 0);
        if (result?.totalPage < tablePage) setTablePage(1);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setTableLoader(false);
    }
  }

  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > tableTotalPages) return;
    setTablePage(newPage);
  };

  const handleDeleteFiles = async(fileName) => {
    try {
      const remainingData = await deleteReportFile(fileName);
      if (remainingData?.status) {
        setNotificationBadge({ showNotification: true, isSuccess: remainingData?.status, message: remainingData?.message });
        retrieveExcelData();
      }
    }
    catch (error) {
      console.error("Failed to delete file:", error.message || error)
      setNotificationBadge({ showNotification: true, isSuccess: false, message: "Failed to delete file" });
    }
  }

  const handleEditClick = async (report) => {
    setBtnLoader(report?.id)
    setSerialId(report?.serialId)
    //  setTooltipOpen(report?.id)
    const response = await fetchExcelData({ filename: report?.fileName })
    console.log('handleEditClick ::response', response)
    if (response?.status) {
      setSelectedReport({ json: response?.excelJsonData });
      setFileName(report?.fileName)
      setUserDetails(report?.userDetails)
      setOpenModal(true);
    }
    setBtnLoader("")
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen("");


  const handleDownload = (fileName) => {
    const fileUrl = `${process.env.REACT_APP_BACKEND_URL}/${fileName}`
    if (fileUrl) {
      window.open(fileUrl, '_blank');
    }
  }

  return (
    <>
      {openModal && <AnalyzeModal openModal={openModal} selectedReport={selectedReport} fileName={fileName} setSelectedReport={setSelectedReport} setOpenModal={setOpenModal} showAIReports={showAIReports} userDetails={userDetails} setUserDetails={setUserDetails} responseId={responseId} abnormalBiomarkers={abnormalBiomarkers} aiResponse={aiResponse} serialId={serialId} summaryContent={summaryContent} isSpinner={isSpinner} setIsSpinner={setIsSpinner} analyzeStart={analyzeStart} setAnalyzeStart={setAnalyzeStart} feedbackDetails={feedbackDetails} setSummaryContent={setSummaryContent} setAiResponse={setAiResponse} retrieveExcelData={retrieveExcelData} />}
      {notificationBadge?.showNotification && <NotificationBadge notificationBadge={notificationBadge} setNotificationBadge={setNotificationBadge} />}
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              {tableLoader ? (
                <div className="text-center my-4">
                  <Spinner style={{ width: "3rem", height: "3rem" }} />
                </div>
              ) : tableData?.length === 0 ? (
                <div className="text-center my-4">
                  <h4>No data available</h4>
                </div>
              ) : (
                <Table className="align-items-center table-flush" style={{ scrollbarWidth: "none" }} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Run Id</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Date</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Analyze By</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Sample Name</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Sample Id</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Race</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Gender</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Age</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Result</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Rating</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>Feedback</th>
                      <th scope="col" style={{ fontSize: "0.8rem" }}>reanalyze</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item, index) => (
                      <tr key={index}>
                        <td style={{ maxWidth: '100px', whiteSpace: 'normal', wordWrap: 'break-word' }} className="text-center">
                          <span className="mb-0 text-sm">{item?.serialId ?? ""}</span>
                        </td>
                        <td>
                          {new Date(item.createdAt).toLocaleDateString('en-US')} {new Date(item.createdAt).toLocaleTimeString('en-US')}
                        </td>
                        <td><span className="mb-0 text-sm">{item?.userId?.name ?? ""}</span></td>
                        <td onClick={() => handleDownload(item?.fileName)} style={{ textAlign: "center", verticalAlign: "middle", cursor: "pointer" }}>
                          {item?.fileName}
                          <IconButton onClick={() => handleDownload(item?.fileName)} aria-label="download" size="small">
                            <DownloadIcon />
                          </IconButton>
                        </td>
                        <td style={{ textAlign: "center", verticalAlign: "middle", cursor: "pointer" }}>
                          {item?.sampleId}
                        </td>
                        <td>{item?.userDetails?.Race ?? ""}</td>
                        <td>{item?.userDetails?.Gender ?? ""}</td>
                        <td>{item?.userDetails?.Age ?? ""}</td>
                        <td>
                          <Badge color="primary" style={{ fontSize: '0.8rem', padding: '0.4rem 1rem', cursor: 'pointer' }}
                            onClick={() => showAIReports(item.aiResponse, item.summary, item.userDetails, item,)}
                          >
                            {loader === item?.id ? (
                              <Spinner
                                size="sm"
                                color="#00c0ed"
                                style={{ margin: '0 0.4rem' }}
                              />
                            ) : 'View'}
                          </Badge>
                        </td>
                        <td>{item.rating}</td>
                        <td
                          style={{
                            maxWidth: '300px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                display: 'block',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                              }}
                            >
                              <ReadMore text={item.feedback} />
                            </span>
                          </div>
                        </td>
                        <td style={{ textAlign: "center", verticalAlign: "middle", display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
                          <Badge
                            id={`tooltip-${item?.id}`}
                            style={{
                              fontSize: '0.8rem',
                              padding: '0.4rem 1rem',
                              background: "rgb(255 88 124)",
                              color: "#ffffff",
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100px',
                            }}
                            onClick={() => handleEditClick(item)}
                          >
                            {btnLoader === item?.id ? (
                              <Spinner
                                size="sm"
                                color="light"
                                style={{ marginRight: '0.5rem' }}
                              />
                            ) : null}
                            Reanalyze
                          </Badge>
                          <Badge
                              style={{padding: '0.3rem 1rem', background: "rgb(213 227 237 / 50%)", color: "#1189ef", cursor: 'pointer', textTransform: "capitalize" }}
                              onClick={() => handleDeleteFiles(item?.fileName)}
                            >
                              <StyledDeleteIcon />
                            </Badge>

                          {tooltipOpen === item?.id && <Tooltip
                            isOpen={tooltipOpen}
                            target={`tooltip-${item?.id}`}
                            toggle={toggleTooltip}
                            placement="top"
                          >
                            Coming Soon
                          </Tooltip>}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              {!tableLoader && tableData?.length > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0">
                      <PaginationItem disabled={tablePage <= 1}>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(tablePage - 1);
                          }}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      {Array.from({ length: tableTotalPages }, (_, i) => (
                        <PaginationItem key={i} active={tablePage === i + 1}>
                          <PaginationLink
                            href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              handlePageChange(i + 1);
                            }}
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={tablePage >= tableTotalPages}>
                        <PaginationLink
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(tablePage + 1);
                          }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tables;
